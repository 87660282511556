import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Background from "../../../images/reference/snowboard-zezula/sz.png"
import PenguinSnowboarder from "../../../images/vyvoj/penguin_snowboarder.svg"
import Logo from "../../../images/reference/snowboard-zezula/logo.png"
import Petr from "../../../images/reference/snowboard-zezula/reaction-devel.png"
import Sideimg from "../../../images/reference/snowboard-zezula/screen-1.png"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class SnowboardZezula extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>
            Reference na e-shopový systém Snowboard Zezula | igloonet
          </title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="snowboard zezula"
          heading="Reference na e-shopový systém Snowboard Zezula"
          date="srpen 2008 - srpen 2021"
        />

        <ReferenceImageText
          right
          img={Background}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Snowboard Zezula"
          link="//snowboard-zezula.cz/"
        >
          <p>
            Snowboard Zezula je specializovaný prodejce snowboardového vybavení
            a doplňků, který nabízí nejširší sortiment snowboardového vybavení v
            ČR.
          </p>
          <p>
            Od roku 1996 provozují prodejnu v Brně a od roku 2004 také e-shop.
            Jako jedni z prvních pochopili důležitost internetových obchodů a
            dělají vše pro to, aby zůstali jedničkou jakožto prodejci i výrobci
            snowboardů
          </p>
        </ReferenceImageText>

        <ReferenceImageText>
          <h2>Zadání</h2>
          <p>
            Převzít vývoj e-shopu www.snowboard-zezula.cz a umožnit rychlejší
            vývoj požadovaných funkcí a opravu chyb.
          </p>
        </ReferenceImageText>

        <ReferenceImageText
          halfImgSize
          responsiveTextOrder
          img={PenguinSnowboarder}
          alt=""
        >
          <h2>Rozsah projektu</h2>
          <ul>
            <li>
              vývoj vlastního e-shopového systému pro maloobchod i velkoobchod
            </li>
            <li>technické konzultace, vývoj a testování nových funkcí</li>
            <li>konzultace grafického řešení</li>
            <li>responzivní design pro frontendovou část e-shopu</li>
            <li>kompletní řešení online marketingu</li>
            <li>
              kompletní zajištění hostingu a IT prostředků na e-shopu i prodejně
            </li>
          </ul>
        </ReferenceImageText>

        <ReferenceImageText halfImgSize right img={Sideimg} alt="">
          <h2>Historie spolupráce</h2>
          <p>
            Na Snowboard Zezula jsme začali pracovat původně jako vývojářská
            výpomoc. Poměrně brzy jsme kompletně převzali vývoj celého e-shopu a
            výrazně rozšířili naši spolupráci. Kvůli mnoha omezením starého
            systému jsme v roce 2012 spustili e-shop na kompletně novém jádře
            spolu s novým frontendem.
          </p>
          <p>
            Od počátku spolupráce řešíme pro Snowboard Zezula nejen samotný
            vývoj, ale i konzultace ohledně dalšího vývoje, grafiky a propagace.
          </p>
          <p>
            Od roku 2011 jsme také převzali správu PPC kampaní a SEO, v současné
            době již řešíme kompletní online marketing Snowboard Zezula.
          </p>
          <p>V srpnu 2021 jsme web předali ke správě internímu týmu e-shopu.</p>
        </ReferenceImageText>

        <PaddingWrapper>
          <Medailon personName="Petr Schwarz" position="ředitel" img={Petr}>
            <p>
              Při výběru obchodního partnera je pro mě důvěra vždy na prvním
              místě, proto jsem si vybral společnost igloonet.
            </p>
          </Medailon>
        </PaddingWrapper>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default SnowboardZezula
